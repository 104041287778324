import React, { useState, useEffect,useRef } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  Alert,
} from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import CabeceraSecundariaScreem from "../Components/CabeceraSecundariaScreem";
import {
    Button,Input
  } from "react-native-elements";
import { useNavigation } from "@react-navigation/native";
import LoadingTransparente from "../Components/LoadingTransparente";
import { COLOR_EMPRESARIAL, COLOR_PLACEHOLDER, COLOR_SECUNDARIO} from "../utils/variables";
import ModalAbajo from "../Components/ModalAbajo"
import Toast from "react-native-easy-toast";
import {ImprimirPreCuenta, VerMesa} from "../utils/consultasAPI/Mesas"
import CambiarMesas from "../Components/CambiarMesas";
import QuitarPedido from "../Components/QuitarPedido";
import { verificarToken } from "../utils/consultasAPI/InicioSesion";
export default function VerMesas({route}) {

  const { params } = route;
  const { Id, Nombre, Grupo } = params;
  const [isVisible_CambiarMesa, setisVisible_CambiarMesa] = useState(false);
  const [isVisible_QuitarPedido, setisVisible_QuitarPedido] = useState(false);
  const toastRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [indexSelect,setIndexSelect]=useState(0);
  const [Mesa,setMesa]=useState(null);
  const [idMesa,setIdMesa]=useState(params.Codigo);
  const [Regreso,setRegreso]=useState("");
  const navigation = useNavigation();
const [ShowImpresionCorrecta,setShowImpresionCorrecta]=useState(false);
const [ShowImpresionError,setShowImpresionError]=useState(false);
const [ShowImpresion,setShowImpresion]=useState(false);
const [ShowMesaVacia,setShowMesaVacia]=useState(false);
  
const Cargar= async ()=>
  {
    setIsLoading(true);
  try {
    const data = await Promise.all([
      VerMesa(params.Id),
      // Otras llamadas asíncronas si las tienes
    ]);
    setIdMesa(params.Codigo);
    console.log("MESOTA",data[0])
    setMesa(data[0]); // Asignar el resultado de la primera llamada a "mesa"
    setIsLoading(false);
  } catch (error) {
    console.log("errrrorrrrrr",error)
    setIsLoading(false);
  }
 }
console.log("IDMESA",idMesa);
 useEffect(() => {
  const loadData = async() => {
    await Cargar();
  };

  navigation.addListener("focus", loadData);
  loadData();

  return () => {
    navigation.removeListener("focus", loadData);
  };
  },[params,isVisible_QuitarPedido,isVisible_CambiarMesa,Regreso]);
  
  function preCuenta() {
    setIsLoading(true);
    ImprimirPreCuenta(params.Codigo)
      .then((p) => {
        setIsLoading(false);
        setShowImpresionCorrecta(true);
      })
      .catch((error) => {
        setIsLoading(false);
        // Mostrar una notificación de error al usuario o realizar otras acciones según sea necesario
        setShowImpresionError(true);
      });
  }
    return(
    <View style={{backgroundColor:"#fff", flex:1}}>
      <CabeceraSecundariaScreem texto={(Mesa==null?"0":Nombre.replace("\n"," - ")+" - "+Grupo)}/>
      <SafeAreaView style={{margin:5, marginBottom:20, flex:1}}>
      {Mesa&&<View style={{flex:1}}>
      <FlatList
//          initialScrollIndex={indexSelect}
          showsVerticalScrollIndicator={true}
          data={Mesa.DetalleComanda}
          style={{flex:1, marginVertical:5, borderColor:COLOR_EMPRESARIAL,padding:10, borderWidth:1, borderRadius:5}}
          renderItem={(cate) => ( 
          <LineaCuota
          item={cate.item}
          indexSelect={indexSelect}
          index={cate.item.IDProducto}
          setIndexSelect={setIndexSelect}
          />)
          }
          keyExtractor={(item, index) => index.toString()}
        />
        <Text 
        style={{textAlign:"right", fontFamily:"FontBold", fontSize:18, color:COLOR_EMPRESARIAL}}>TOTAL PEDIDO    S/ {Mesa.MontoTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
        {Mesa.DetalleComanda.length>0&&
        <TiempoTranscurrido FechaPedido={Mesa.FechaPedido}/>}
        <Button
                onPress={()=> Mesa.DetalleComanda.length>0?setisVisible_CambiarMesa(true):setShowMesaVacia(true)}
                titleStyle={{fontFamily:"FontBold", fontSize:18}} 
                title={"CAMBIAR MESA"} 
                buttonStyle={{backgroundColor:COLOR_PLACEHOLDER,marginVertical:5}} />
        <Button 
                onPress={()=>Mesa.DetalleComanda.length>0?setisVisible_QuitarPedido(true):setShowMesaVacia(true)}
                titleStyle={{fontFamily:"FontBold", fontSize:18}}
                title={"QUITAR PEDIDO"}
                buttonStyle={{backgroundColor:"red",marginVertical:5}} />
        <Button 
        onPress={()=>navigation.navigate("AgregarPedido",{IdMesa:idMesa, emesa:params,setRegreso:setRegreso})}
        titleStyle={{fontFamily:"FontBold", fontSize:18}} title={"AGREGAR PEDIDO"} buttonStyle={{backgroundColor:COLOR_EMPRESARIAL}} />
        <Button 
        onPress={()=>{Mesa.DetalleComanda.length>0?setShowImpresion(true):setShowMesaVacia(true) }}
        titleStyle={{fontFamily:"FontBold", fontSize:18}} title={"IMPRIMIR PRE-CUENTA"} buttonStyle={{backgroundColor:"green",marginVertical:5}} />
      </View>}
      {Mesa&&<ModalAbajo
                isVisible={isVisible_CambiarMesa}
                setIsVisible={setisVisible_CambiarMesa}
                children={
                  <CambiarMesas setIdMesa={setIdMesa} mesaOrigen={idMesa} setisVisible_CambiarMesa={setisVisible_CambiarMesa} setIsLoading={setIsLoading} setRegreso={setRegreso}/>
                }
              />}
              {Mesa&&<ModalAbajo
                isVisible={isVisible_QuitarPedido}
                setIsVisible={setisVisible_QuitarPedido}
                children={
                  <QuitarPedido mesaOrigen={params} setisVisible_QuitarPedido={setisVisible_QuitarPedido} setIsLoading={setIsLoading} CargarMesa={Cargar}/>
                }
              />}
{isLoading&&<LoadingTransparente isVisible={true}/>}
<Toast
        position="center"
        positionValue={200}
        opacity={0.8}
        ref={toastRef}
      />
      <AwesomeAlert
          show={ShowImpresion}
          showProgress={false}
          title="Alerta"
          message={"¿Desea imprimir la pre-cuenta?"}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true} showCancelButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          cancelButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Si"
          cancelText="No"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowImpresion(false);
          }}
          onConfirmPressed={() => {
            preCuenta();
            setShowImpresion(false);
          }}
        />
        <AwesomeAlert
          show={ShowImpresionCorrecta}
          showProgress={false}
          title="Alerta"
          message={"Impresión correcta."}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowImpresionCorrecta(false);
          }}
          onConfirmPressed={() => {
            setShowImpresionCorrecta(false);
          }}
        />
        <AwesomeAlert
          show={ShowMesaVacia}
          showProgress={false}
          title="Alerta"
          message={"La mesa de encuentra vacía."}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowMesaVacia(false);
          }}
          onConfirmPressed={() => {
            setShowMesaVacia(false);
          }}
        />
        <AwesomeAlert
          show={ShowImpresionError}
          showProgress={false}
          title="Error"
          message={"Ocurrió un error al imprimir la pre-cuenta."}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowImpresionError(false);
          }}
          onConfirmPressed={() => {
            setShowImpresionError(false);
          }}
        />              
      </SafeAreaView>
    </View>)
} 

const LineaCuota = ({ item, indexSelect, index }) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", width: "15%",color:"#292728" }}>{item.Cantidad.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", flex: 1,color:"#292728" }}>{item.ProductoDetalleTotal}</Text>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", width: "15%", textAlign: "right",color:"#292728" }}>{item.Total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
    </View>
  );
};

const TiempoTranscurrido = (props) => {
  const [tiempoTranscurrido, setTiempoTranscurrido] = useState('');
  const [recargando,setRecargando]=useState(true);
  useEffect(() => {
    const horaEspecifica = new Date(props.FechaPedido);
    const intervalo = setInterval(() => {
      const tiempoActual = new Date();
      const diferenciaTiempo = tiempoActual - horaEspecifica;

      const horas = Math.floor(diferenciaTiempo / (1000 * 60 * 60)).toString().padStart(2, '0');
      const minutos = Math.floor((diferenciaTiempo / (1000 * 60)) % 60).toString().padStart(2, '0');
      const segundos = Math.floor((diferenciaTiempo / 1000) % 60).toString().padStart(2, '0');

      setTiempoTranscurrido(`${horas}:${minutos}:${segundos}`);
    }, 500);
  
    setRecargando(false);
    return () => clearInterval(intervalo);
  }, [props.FechaPedido]);

  return (
    <View>
      <Text style={{fontFamily:"FontLight", fontSize:16}}>Tiempo mesa activa: {tiempoTranscurrido}</Text>
    </View>
  );
};