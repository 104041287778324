import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  
  Pressable,
  Alert
} from "react-native";

import { Input, Icon, Button } from "react-native-elements";
import { isEmpty } from "lodash";
import styleCajatexto from "../../utils/css/CajaTexto";
import Loading from "../Loading";
import { verificarToken } from "../../utils/consultasAPI/InicioSesion";
import { COLOR_EMPRESARIAL } from "../../utils/variables";
import { IniciarSesion } from "../../utils/consultasAPI/InicioSesion";
import { useNavigation } from "@react-navigation/native";

export default function InicioSesion(props) {
  const navigation = useNavigation();
  const { toastRef, desdeCuenta } = props;
  const [MostrarPassword, setMostrarPassword] = useState(false);
  const [FormData, setFormData] = useState({Username:"",Password:""});
  const [isLoading, setIsLoading] = useState(false);
  const [tieneToken, setTieneToken] = useState(false);
  useEffect(() => {
    verificarToken().then((result) => {
      if (result) {
        if (props.desdeCuenta) props.handler(true);
        else {
          navigation.navigate("MenuInicio");
        }
      }
    });
  }, [tieneToken]);

  async function fn_IniciarSesion() {
    setIsLoading(true);
    return IniciarSesion(FormData)
      .then((result) => {
        setIsLoading(false);
        console.log("reeess", result);
        if (result) {
          if (props.desdeCuenta) {
            props.handler(true);
          } else {
            navigation.navigate("MenuInicio");
          }
        } else toastRef.current.show("Usuario / Contraseña incorrecto.");
      })
      .catch((err) => {
        setIsLoading(false);
        toastRef.current.show(err);
        setTieneToken(false);
      });
  }
  const onSubmit = () => {
    if (isEmpty(FormData.Username) || isEmpty(FormData.Password)) {
      toastRef.current.show("Todos los campos deben de llenarse");
    }else {
      fn_IniciarSesion();
    }
  };
  const onChange = (e, type) => {
    setFormData({ ...FormData, [type]: e.nativeEvent.text });
  };

  const updateText = (v) => {
    const { onChange } = this.props;
    this.setState({ text: v });
    onChange(v);
  };

  return (
    <View style={styles.viewbtn}>
    <View style={{ width: "80%" }}>
        <Input
          containerStyle={{ marginBottom: 20 }}
          placeholder="Usuario"
          defaultValue={FormData.Username}
          onChange={(e) => onChange(e, "Username")}
          leftIconContainerStyle={styleCajatexto.IconLeft}
          leftIcon={
            <Icon
            type='ionicon'
            name='person-outline'
            color={COLOR_EMPRESARIAL}
              size={30}
            />
          }
        />
        <Input
          placeholder="Contraseña"
          defaultValue={FormData.Password}
          onChange={(e) => onChange(e, "Password")}
          secureTextEntry={MostrarPassword ? false : true}
          leftIconContainerStyle={styleCajatexto.IconLeft}
          rightIconContainerStyle={styleCajatexto.IconLeft}
          rightIcon={
            <Icon
            type='ionicon'
              name={MostrarPassword ? "eye-off-outline" : "eye-outline"}
              onPress={() => setMostrarPassword(!MostrarPassword)}
              color={COLOR_EMPRESARIAL}
              size={30}
            />
          }
          leftIcon={
            <Icon
              name="lock-closed-outline"
              type='ionicon'
              color={COLOR_EMPRESARIAL}
              size={30}
            />
          }
        />

        <View style={styles.viewtxt}></View>
        <View style={{ marginBottom: 30 }}>
          <Pressable
            activeOpacity={0.6}
            underlayColor="#DDDDDD"
            onPress={() => Alert.alert("Alerta","Comunícate con el desarrollador para poder restablecer tu contraseña")}
          >
            <Text style={styles.textbutton}>¿Olvidaste tu contraseña?</Text>
          </Pressable>
        </View>
        <Button
        containerStyle={{paddingHorizontal:10,}}
          titleStyle={{ fontFamily: "FontBoton", fontSize: 20 }}
          buttonStyle={{ backgroundColor: COLOR_EMPRESARIAL, }}
          onPress={() => onSubmit()}
          title="Ingresar"
        ></Button>
      </View>
      <Loading isVisible={isLoading} />
    </View>
  );
}

const styles = StyleSheet.create({
  descripcion: {
    marginBottom: 20,
    textAlign: "center",
  },
  viewbtn: {
    flex: 1,
    alignItems: "center",
    alignContent: "center",
    marginTop: 10,
    width: "100%",
  },
  viewtxt: {
    width: "90%",
    marginTop: 10,
    marginBottom: 10,

    //justifyContent: "space-around",
    flexDirection: "row-reverse",
    //textAlign: "right",
  },

  textbutton: {
    fontFamily: "FontBold",
    color: "#3b5999",
    alignContent: "center",
    alignSelf: "center",
  },
  btnStyle: {
    backgroundColor: "#02ACF3",

    marginTop: 10,
  },
  btnContainer: {
    width: "90%",
  },
});
