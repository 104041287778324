import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,FlatList,Pressable,
  Image,
  SafeAreaView,
  ImageBackground,
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage'
import CabeceraScreem from "../Components/CabeceraScreem";
import { useNavigation } from "@react-navigation/native";
import { CambiarNombre, ListarMesas } from "../utils/consultasAPI/Mesas";
import { COLOR_EMPRESARIAL } from "../utils/variables";
import { Button, Input } from "react-native-elements";
import Modal from "../Components/Modal";
import jwtDecode from "jwt-decode";
import { useSignalR } from "../SingalContet";
import Logo_Cabecera from "../Components/Logo_Cabecera";
function useMesas(){
  const [mesas, setMesas]=useState([]);
  const [grupos, setGrupos]=useState([]);
  const [recargando,setRecargando]=useState(false);
  const ListaMesas=()=>{
    setRecargando(true);
    ListarMesas().then((p)=>{
      setMesas(p);
      const gruposUnicos = new Set();
      p.forEach(dato=>{
        gruposUnicos.add(dato.Grupo);
      })
      setGrupos([...gruposUnicos]);
      setRecargando(false);

    }).catch((ex)=>{ setRecargando(false);
  });
  }

  const Limpiar =()=>{
    setMesas([]);
  }

  return {mesas,grupos,ListaMesas,recargando,Limpiar};
}


export default function Categorias() {
  const navigation = useNavigation();
  const { width } = Dimensions.get("window");
  const [visibleCambioNombre, setVisibleCambioNombre]=useState(false);
  const [NomEmpresa,setNomEmpresa]=useState("");
  const [usuarioActivo,setUsuarioActivo]=useState("")
  const [Local, setLocal]=useState("");

  const [isLoading, setIsLoading] = useState(false);
  const {grupos,mesas,ListaMesas,recargando,Limpiar}=useMesas();
const [mesaSelect,setMesaSelect]=useState(null);
const[text,setText]=useState("");
const { connection, connectionState, startConnection, stopConnection } = useSignalR();

useEffect(()=>{setText("")},[visibleCambioNombre])

  function LeerToke() {
    return AsyncStorage.getItem("Token")
      .then((p) => {
        return p;
      })
      .catch((p) => {
        return "";
      });
  }
  useEffect(() => {
    const loadInitialData =  async () => {
      const ttttt = await LeerToke();
      console.log("leyendo token",ttttt);
      if (ttttt === null || ttttt === "" || ttttt === "null") {
        navigation.navigate("InicioSesion");
        return;
      } else {
        if (jwtDecode(ttttt).IdEmpresa == 0) {
          navigation.navigate("ListaEmpresas");
          return;
        }
        else if(jwtDecode(ttttt).IdLocal)
        {
          if (jwtDecode(ttttt).IdLocal == 0) {
            navigation.navigate("ListaLocales");
            return;
          }
          else{
            setNomEmpresa(jwtDecode(ttttt).Empresa);
      setUsuarioActivo(jwtDecode(ttttt).unique_name);
      setLocal(jwtDecode(ttttt).Local);
      ListaMesas();
      ///console.log("Estado",connectionState)
      ////////////////////////////startConnection();
          }
        }
        else
        {
            navigation.navigate("ListaLocales");
            return;
        }
      }
    };
    const unsubscribe = navigation.addListener("focus", loadInitialData);

    return()=>{ unsubscribe();};

  }, []);


/*
  useEffect(() => {
    if (connection && connection.state === 'Connected') {
      // Suscribirse a eventos de SignalR
        connection.on('ReceiveMesas', (message) => {
          console.log('Mensaje recibido: ', message);
        });
      console.log("Invocando a OPnCLienrte");

      connection.invoke("OnClientConnected","1006").then(p=>console.log("correcto",p)).catch(pp=>console.log("erropr ",pp));
      connection.invoke("EnviarMesasActualizadas","1006").then(p=>console.log("correcto2",p)).catch(pp=>console.log("erropr 2",pp));


      // Limpiar la suscripción cuando el componente se desmonte
      return () => {
        connection.off('ReceiveMesas');
      };
    }
  }, [connection]);
*/
  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <CabeceraScreem texto={NomEmpresa}  />
      <SafeAreaView style={{flex:1}}>
      <Logo_Cabecera/>
       <View style={{ marginHorizontal:10, marginTop:10, flexDirection:"row"}}>
         <Text style={{fontFamily:"FontRegular",  fontSize:18}}>Hola </Text>
         <Text style={{fontFamily:"FontBold",  fontSize:18}}>{usuarioActivo.toUpperCase()}</Text>
         </View>
         <Text style={{ marginHorizontal:10, marginBottom:10, fontFamily:"FontRegular",  fontSize:18}}>{"Local: "+ Local}</Text>
         <View style={{flex:1, marginHorizontal:10}}>
       {recargando? <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignContent: "center",
          alignSelf: "center",
          alignItems: "center"
        }}
      >
        <View
          style={{ backgroundColor: "#fff", padding: 10, borderRadius: 100 }}
        >
          <ImageBackground
            source={require("../../assets/cargdor.gif")}
            style={{
              width: 100,
              height: 100,
            }}
          >
          </ImageBackground>
        </View>
      </View>:
      <FlatList
      refreshing={recargando}
      onRefresh={()=>ListaMesas()}
      data={grupos}
      keyExtractor={(item, index) => index.toString()}
      ListHeaderComponent={<Pressable onPress={()=>ListaMesas()}><Text style={{fontFamily:"FontBold",fontSize:25,textAlign:"center",color:"#22223e"}}>Mesas</Text></Pressable>}
      renderItem={(item) => (
          <FlatList
          ListHeaderComponent={<Pressable
            style={{
              margin: 1,
              backgroundColor: "#fff",
              minWidth: 80,
              height: 50,
              borderColor: COLOR_EMPRESARIAL,
              borderRadius: 10,
              borderWidth: 2,
              justifyContent: "center",
            }}
            onPress={()=>{}}
          >
            <Text
              style={{
                fontFamily: "FontBold",
                fontSize: 15,
                textAlign: "center",
                color: COLOR_EMPRESARIAL,
              }}
            >
              {item.item}
            </Text>
          </Pressable>}
            keyExtractor={(item, indexx) => indexx.toString()}
            numColumns={5}
            data={mesas.filter((p) => p.Grupo == item.item)}
            renderItem={(mesita) => (
              <Mesa
                item={mesita.item}
                width={width}
                navigation={navigation}
                visibleCambioNombre={visibleCambioNombre}
                setVisibleCambioNombre={setVisibleCambioNombre}
                setMesaSelect={setMesaSelect}
              />
            )}
          />
      )}
    />}
    </View>
    <Modal isVisible={visibleCambioNombre} setIsVisible={setVisibleCambioNombre}>
      {mesaSelect&&
        <><Text style={{fontFamily:"FontBold", fontSize:14}}>Cambiar nombre de mesa {mesaSelect.Nombre.replace("\n"," - ")}:</Text>
        <Input
            inputStyle={{
              fontFamily: "FontRegular",
              marginTop: 10,
            }}
            multiline={false}
            maxLength={50}
            placeholder="Nombre de mesa..."
            blurOnSubmit={true}
            onChange={(e) => setText(e.nativeEvent.text)}
            onSubmitEditing={(e) => setText(e.nativeEvent.text)}
          />
          <Button onPress={()=>{ console.log(mesaSelect.Codigo,text); CambiarNombre(mesaSelect.Codigo,text).then(()=>{setVisibleCambioNombre(false);ListaMesas();})}} titleStyle={{ fontFamily: "FontBold", fontSize: 18 }} title={"CAMBIAR NOMBRE"} buttonStyle={{ backgroundColor: COLOR_EMPRESARIAL }} />
          </>}
      </Modal>
      </SafeAreaView>
      </View>
  );
}



class Mesa extends React.PureComponent{
  render(){
    return<Pressable
    onLongPress={()=>{ console.log("->",this.props.item.Nombre); this.props.setVisibleCambioNombre(!this.props.item.Bloqueado&&this.props.item.Ocupado);this.props.setMesaSelect(this.props.item)}}
        onPress={()=>{console.log("item del clic",this.props.item); !this.props.item.Bloqueado?this.props.navigation.navigate("VerMesa",this.props.item):"Bloqueado"}}
        style={{
          margin:2,
          justifyContent:"center",
          backgroundColor:this.props.item.Bloqueado?"#C8C8C8":this.props.item.Ocupado?"#ff6961":"#0ebb72",
          borderRadius: 10,
          width: this.props.width / 5-5,
          height: this.props.width / 5 - 20
        }}
      >
        <Text
          numberOfLines={2}
          style={{
            textAlign:"center",
            fontFamily:
              /*categoriaItem.item.Id === selectCategoria.IdCategoria
                ? "FontBold"
                :*/ "FontBold",
            color:
              /*  categoriaItem.item.Id === selectCategoria.IdCategoria
                ? "#fff"
                :*/ "#fff", //COLOR_EMPRESARIAL,
            fontSize: 13,
            marginHorizontal: 0,
            zIndex: 10,
          }}
        >
          {this.props.item.Nombre}
        </Text>
      </Pressable>
  }
}