import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Platform,
  StatusBar,
  Pressable,
} from "react-native";
import { Icon, Badge } from "react-native-elements";
import { useNavigation } from "@react-navigation/native";
import { COLOR_EMPRESARIAL, COLOR_SECUNDARIO } from "../utils/variables";
import { CarritoCompra_CantTotal } from "../utils/consultasAPI/CarritoCompra";

export default function CabeceraSecundariaScreem(props) {
  const { Actualizar } = props;
  const navigation = useNavigation();
  const { texto } = props;
  const [cantidad, setCant] = useState(0);
  useEffect(() => {
    CarritoCompra_CantTotal()
      .then((p) => setCant(p))
      .catch(() => setCant(0));
  }, [Actualizar]);
  return (
    <View style={{ zIndex: 1001 }}>
      <View
        style={{
          top: 0,
          height:Platform.OS=='ios'?35:StatusBar.currentHeight,
          zIndex: 1001,
          backgroundColor: COLOR_EMPRESARIAL,
        }}
      />

      <View
        style={{
          height: 50,
          flexDirection: "row",
          paddingLeft: 20,
          backgroundColor: COLOR_EMPRESARIAL,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Pressable onPress={() => navigation.goBack()}>
          <View
            style={{
              borderRadius: 5,
              paddingHorizontal: 5,
            }}
          >
            <Icon
              color="white"
              type="material-community"
              name="arrow-left"
              size={30}
            />
          </View>
        </Pressable>
        <View
          style={{ flex: 1, justifyContent: "center", marginHorizontal: 10 }}
        >
          <Text
            style={{
              fontSize: 15,
              fontFamily: "FontBold",
              color: "white",
              verticalAlign: "center",
            }}
            numberOfLines={1}
          >
            {texto}
          </Text>
        </View>
        <View style={{ marginRight: 20 }}>
          {props.Carrito && (
            <Pressable onPress={() => navigation.navigate("Cesta")}>
              <View
                style={{
                  borderRadius: 5,
                  paddingHorizontal: 5,
                }}
              >
                <Icon
                  color={"#fff"}
                  type="material-community"
                  name="cart-outline"
                  size={30}
                  onPress={() => navigation.navigate("Cesta")}
                />
                {(cantidad || cantidad !== 0) && (
                  <Badge
                    badgeStyle={{ backgroundColor: COLOR_SECUNDARIO }}
                    value={cantidad}
                    containerStyle={{ position: "absolute", top: 0, right: -5 }}
                  />
                )}
              </View>
            </Pressable>
          )}
        </View>
      </View>
    </View>
  );
}
