import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { Badge } from 'react-native-elements';

class ProductoItemBase extends React.PureComponent {
  handlePress = () => {
    const { setClic, setProductoClic, cantidad, producto } = this.props;
    setClic(Date.now());
    setProductoClic({ agregar: !(cantidad > 0), producto });
  };

  render() {
    const { children, cantidad, producto } = this.props;
    const backgroundColor = cantidad > 0 ? "#ddd" : "#fff";

    return (
      <TouchableOpacity
        style={styles.container}
        onPress={this.handlePress}
      >
        <View style={styles.innerContainer}>
          <View style={[styles.box, { backgroundColor }]}>
            <View style={styles.textContainer}>
              {children}
              <Text style={styles.priceText}>
                S/ {parseFloat(producto._PrecioActual).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}
class ProductoItemBase2 extends React.PureComponent {
  
  render() {
    const { children, producto } = this.props;
    const backgroundColor = "#fff";
console.log("Dentro del boton",producto)
    return (
      <TouchableOpacity
        style={styles.container}
        onPress={this.props.OnPress}
      >
        <View style={styles.innerContainer}>
          <View style={[styles.box, { backgroundColor }]}>
            <View style={styles.textContainer}>
              {children}
              <Text style={styles.priceText}>
                S/ {parseFloat(producto.Precio).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    paddingVertical: 3,
    marginHorizontal: 5,
    marginBottom: 5,
  },
  innerContainer: {
    alignContent: "center",
    justifyContent: "space-around",
  },
  box: {
    flexDirection: "row",
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
  },
  textContainer: {
    flex: 1,
    marginLeft: 10,
    justifyContent: "space-between",
    marginBottom: 5,
  },
  productName: {
    textAlign: "center",
    fontFamily: "FontBold",
    color: "#444",
    fontSize: 14,
  },
  priceText: {
    fontFamily: "FontBold",
    textAlign: "center",
    color: "red",
    fontSize: 18,
  },
};

// Componente ProductoItemCombo
class ProductoItemCombo extends React.PureComponent {
  render() {
    return (
      <ProductoItemBase
        {...this.props}
      >
        <Text style={styles.productName} numberOfLines={2}>
          {this.props.producto.Nombre}
        </Text>
      </ProductoItemBase>
    );
  }
}

// Componente ProductoItemExtra
class ProductoItemExtra extends React.PureComponent {
  render() {
    return (
      <ProductoItemBase
        {...this.props}
      >
        <Text style={styles.productName} numberOfLines={2}>
          {this.props.producto.Nombre}
        </Text>
      </ProductoItemBase>
    );
  }
}

class ProductoItemHijo extends React.PureComponent {
  render() {
    console.log("hiiijoo",this.props)
    return (
      <ProductoItemBase2
        {...this.props}
      >
        <Text style={styles.productName} numberOfLines={2}>
          {this.props.producto.NombreProducto}
        </Text>
      </ProductoItemBase2>
    );
  }
}

// Componente ProductoItemEstado
class ProductoItemEstado extends React.PureComponent {
  render() {
    return (
      <ProductoItemBase
        {...this.props}
      >
        <Text style={styles.productName}>
          {this.props.producto.Estado}
        </Text>
      </ProductoItemBase>
    );
  }
}

export { ProductoItemCombo, ProductoItemExtra, ProductoItemEstado,ProductoItemHijo };