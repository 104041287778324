import { size } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {   Animated, FlatList, Text, Pressable, View,SafeAreaView, Keyboard
} from "react-native";
import Toast from "react-native-easy-toast";
import { Badge, Button,SearchBar  } from "react-native-elements";
import CabeceraSecundariaScreem from "../Components/CabeceraSecundariaScreem";
import {  ListarAlmacenes, RegistrarComanda } from "../utils/consultasAPI/Mesas";
import { useNavigation } from "@react-navigation/native";
import { Lista, ListarCategorias,ListarProductosCombo,ListarProductosEstados,ListarProductosExtra } from "../utils/consultasAPI/Productos";
import {
  COLOR_EMPRESARIAL,
  generateUUID,
} from "../utils/variables";
import LoadingTransparente from "./LoadingTransparente";
import ModalAbajo from "./ModalAbajo";
import Modal from "./Modal";
import AwesomeAlert from "react-native-awesome-alerts";
import { useToast } from "react-native-toast-notifications";
import { ProductoItemCombo, ProductoItemExtra, ProductoItemEstado, ProductoItemHijo  } from "./Pedido/ComponenteProductos";
import {Styles} from "../utils/EstilosAgregarPedido";
import LineaCuota from "./Pedido/LineaCuota"
import {ViewDetalle,ViewObservacion} from "./Pedido/ViewPedido"
export default function AgregarPedido(props) {
  const ToastWeb= useToast();

  const refContainer3=useRef();
  const navigation=useNavigation();
  const [buscarProducto,setBuscarProducto]=useState("");
  const [loadingTrans, setLoadingTrans] = useState(false);
  const [isVisible_Observacion, setisVisible_Observacion] = useState(false);
  const [isVisible_Combo, setisVisible_Combo] = useState(false);
  const [isVisible_Hijos, setisVisible_Hijos] = useState(false);
  const [isVisible_Detalle, setisVisible_Detalle] = useState(false);
  const toastRef = useRef();
const [categorias,setCategorias]=useState([]);
const [listaProductos,setListaProductos]=useState([])
const [listaProductosFiltrado,setListaProductosFiltrado]=useState([]);
const [listaProductosCombo,setListaProductosCombo]=useState([])
  const scrollY = new Animated.Value(0);
const [productosPedido,setProductosPedido]=useState([]);
const [productosCombo,setProductosCombo]=useState([]);
const [productoHijo,setProductoHijo]=useState([]);
const [clic,setClic]=useState(null);
const [clic1,setClic1]=useState(null);
const [clic2,setClic2]=useState(null);
const [productoClic,setProductoClic]=useState({});
const [clic3,setClic3]=useState(null);
const [productoClic1,setProductoClic1]=useState({});
const [productoClic2,setProductoClic2]=useState({});
const [productoClic3,setProductoClic3]=useState({});
const [productoComboSelect,setProductoComboSelect]=useState({});
const [pedido,setPedido]=useState({Observaciones:""})
const [Observaciones, setTObservacion] = useState("");
const [txtDetalle, setTxtDetalle] = useState("");
const [ListaAlmacenes,setListaAmacenes]=useState([]);
const [LocalSelect,setLocalSelect]=useState(0);
const [estado,setEstado]=useState(null);
const [showSeleccioneProductos,setShowSeleccioneProductos]=useState(false);
const [showMaximoCombo, setShowMaximoCombo]=useState(false);
const [showLimpiar, setShowLimpiar]=useState(false);
const [showRegistrar, setShowRegistrar]=useState(false);
const [showExito,setShowExito]=useState(false);
const [productosExtra,setProductosExtra]=useState([])
const [listaProductosExtra,setListaProductosExtra]=useState([])
const [listaHijos,setListaHijos]=useState([])
const [productosExtraSelect,setProductoExtraSelect]=useState({})
const [ProductoHijoSelect,setProductoHijoSelect]=useState({Presentaciones:[]});
const [isVisible_Extra,setIsVisible_Extra]=useState(false);
const [isVisiblePedido,setVisiblePedido]=useState(false);
const [productosEstado,setProductosEstado]=useState([])
const [listaProductosEstado,setListaProductosEstado]=useState([])
const [productosEstadoSelect,setProductoEstadoSelect]=useState({})
const [isVisible_Estado,setIsVisible_Estado]=useState(false);
const [idIsColapsable,setIdColapsable]=useState(-1);
const [colapsableBuscando,setColapsableBuscando]=useState(true);

async function ListarCategoriasProductos(_idAlmacen, _idLocal) {
  try {
    setLocalSelect(_idAlmacen);  // Establecemos el almacén seleccionado
    setLoadingTrans(true);       // Activamos el estado de carga
    // Obtener las categorías
    const categorias = await ListarCategorias(_idLocal);
    setCategorias(categorias);
    // Obtener la lista de productos
    const productos = await Lista(_idAlmacen);
    console.log("POOOO",productos);
    setListaProductos(productos);
    setListaProductosFiltrado(productos);  // Filtrado con la lista completa
  } catch (error) {
    console.error('Error al listar las categorías o productos:', error);
    setListaProductos([]);
    setListaProductosFiltrado([]);
  } finally {
    setLoadingTrans(false);  // Desactivamos el estado de carga
  }
}
useEffect(() => {
  const initData = async () => {
    try {
      setLoadingTrans(true);  // Inicia la carga
      // Obtener los almacenes
      const Almacenes = await ListarAlmacenes();
      setListaAmacenes(Almacenes);
      if (Almacenes.length > 0) {
        // Seleccionar el primer almacén y sus categorías
        const primerAlmacen = Almacenes[0];
        const primerLocalId = primerAlmacen.Empresa.Locales[0].Id;
        await ListarCategoriasProductos(primerAlmacen.Id, primerLocalId);
        setLocalSelect(primerAlmacen.Id);  // Establecer el almacén seleccionado
      }
      // Inicializar el resto de estados
      setProductosPedido([]);
      setTObservacion("");
      setPedido({ Observaciones: "" });
      setBuscarProducto("");
      setIdColapsable(-1);
    } catch (error) {
      console.error('Error al inicializar datos:', error);
      // Aquí podrías manejar cualquier error que ocurra
    } finally {
      setLoadingTrans(false);  // Finaliza la carga
    }
  };
  initData();  // Llamamos a la función asincrónica
}, [props]);

const productosFiltrados = useMemo(() => {
  const terminoBusqueda = buscarProducto.trim().toUpperCase();
  
  return terminoBusqueda === ""
    ? listaProductos
    : listaProductos.filter(producto => 
        producto.Nombre.toUpperCase().includes(terminoBusqueda)
      );
}, [buscarProducto, listaProductos]);

useEffect(() => {
  setListaProductosFiltrado(productosFiltrados);
  console.log("LISTA", productosFiltrados);
}, [productosFiltrados]);

useEffect(() => {
  const manejarProducto = (clic, productoClic, agregarFunc, quitarFunc, setClic) => {
    if (clic !== null) {
      if (productoClic.agregar) {
        agregarFunc(productoClic.producto);
      } else {
        quitarFunc(productoClic.producto);
      }
      setClic(null);  // Restablecemos el clic a null al final
    }
  };

  // Llamamos a la función generalizada para cada caso
  manejarProducto(clic, productoClic, AgregarProducto, QuitarProducto, setClic);
  manejarProducto(clic1, productoClic1, AgregarProductoCombo, QuitarProductoCombo, setClic1);
  manejarProducto(clic2, productoClic2, AgregarProductoExtra, QuitarProductoExtra, setClic2);
  manejarProducto(clic3, productoClic3, AgregarProductoEstado, QuitarProductoEstado, setClic3);

}, [clic, clic1, clic2, clic3, productoClic, productoClic1, productoClic2, productoClic3]);

  

/////////////AGREGAR EL PRODUCTOOO!!!!
function AgregarProducto(Cod) {
  let nuevoCarrito = [...productosPedido]; // Copiamos el array en lugar de mutarlo directamente
  let index = nuevoCarrito.findIndex(p => p.Gui === Cod.Gui);

  // Si tiene presentaciones, abrir el modal de selección de presentación

  if (index >= 0) {
      nuevoCarrito[index].Cantidad += 1;
      setProductosPedido(nuevoCarrito);
  }
  else if (Cod.Presentaciones.length > 0) {
    setisVisible_Hijos(true);
    setProductoHijoSelect(Cod);
    console.log("Hijo",Cod);
} else if (Cod.EsCombo) {
      abrirModalPresentacion(Cod, ListarProductosCombo, setListaProductosCombo, setProductoComboSelect, setisVisible_Combo);
  } else if (Cod.EsExtra) {
      abrirModalPresentacion(Cod, ListarProductosExtra, setListaProductosExtra, setProductoExtraSelect, setIsVisible_Extra);
  } else if (Cod.EsEstado) {
      abrirModalPresentacion(Cod, ListarProductosEstados, setListaProductosEstado, setProductoEstadoSelect, setIsVisible_Estado);
  } else {
      nuevoCarrito.push({ Gui: generateUUID(), index: Math.random(), Cantidad: 1, Producto: Cod, PrecioUnitario: Cod._PrecioActual });
      setProductosPedido(nuevoCarrito);
      ToastWeb.show(`PRODUCTO AGREGADO:\n${Cod.Nombre}`, { duration: 2000 });
  }
}

function abrirModalPresentacion(Cod, listarProductos, setLista, setProducto, setVisible) {
  setLista([]);
  listarProductos(Cod.Id).then(p => setLista(p));
  setProducto(Cod);
  setVisible(true);
}

function AgregarProductoCombo(Cod) {
  let nuevoCarrito = [...productosCombo]; // Copiamos para evitar mutación directa
  if (nuevoCarrito.length < productoComboSelect.MaxCombo) {
      nuevoCarrito.push(Cod);
      setProductosCombo(nuevoCarrito);
  } else {
      setShowMaximoCombo(true);
  }
}

function AgregarProductoExtra(Cod) {
  setProductosExtra([...productosExtra, Cod]); // Agregamos al array
}

function AgregarProductoEstado(Cod) {
  setEstado(Cod);
}
function QuitarProductoEstado(Cod) {
  setEstado(null);
}

function QuitarProductoExtra(Cod) {
  setProductosExtra(prev => prev.filter(p => p.Id !== Cod.Id)); // Eliminamos el producto del array
}

function ContinuarProductoExtra() {
  let total = productosExtra.reduce((acc, p) => acc + p._PrecioActual, 0);
  let guiiid = generateUUID();
  let extras = productosExtra.map(p => ({
      Gui: guiiid,
      Cantidad: 1,
      Producto: { ...p, IdAlmacen: productosExtraSelect.IdAlmacen },
      PrecioUnitario: p._PrecioActual,
      EsExtra: true
  }));

  let nuevoProducto = {
      Gui: guiiid,
      Cantidad: 1,
      Producto: { ...productosExtraSelect, _PrecioActual: productosExtraSelect._PrecioActual + total },
      PrecioUnitario: productosExtraSelect._PrecioActual,
      Extras: extras,
      EsExtra: false,
      ListaProductosCombo: extras.map(e => e.Producto.Nombre).join("\n+ ")
  };

  setProductosPedido(prev => [...prev, nuevoProducto]);
  setListaProductosExtra([]);
  setIsVisible_Extra(false);
  ToastWeb.show(`PRODUCTO AGREGADO:\n${productosExtraSelect.Nombre}`, { duration: 2000 });
}

function ContinuarProductoEstado() {
  let guiiid = generateUUID();
  let nuevoProducto = {
      Gui: guiiid,
      Cantidad: 1,
      Producto: { ...productosEstadoSelect, Estado: estado ? { Id: estado.Id, Estado: estado.Estado } : undefined },
      PrecioUnitario: productosEstadoSelect._PrecioActual,
      ListaProductosCombo: estado ? ` - ${estado.Estado}` : ""
  };

  setProductosPedido(prev => [...prev, nuevoProducto]);
  setListaProductosEstado([]);
  setIsVisible_Estado(false);
  setEstado(null);

  ToastWeb.show(`PRODUCTO AGREGADO:\n${productosEstadoSelect.Nombre}${estado ? "\n" + estado.Estado : ""}`, { duration: 2000 });
}

function ContinuarProductoCombo() {
  if (productosCombo.length === productoComboSelect.MaxCombo) {
      let maxPrecio = Math.max(...productosCombo.map(p => p._PrecioActual));
      let TEXTO = productosCombo.map(p => p.Nombre).join("/");

      let nuevoProducto = {
          Gui: generateUUID(),
          Cantidad: 1,
          Producto: { ...productoComboSelect, _PrecioActual: maxPrecio },
          PrecioUnitario: maxPrecio,
          ProductosCombo: productosCombo,
          EsCombo: true,
          ListaProductosCombo: TEXTO
      };

      setProductosPedido(prev => [...prev, nuevoProducto]);
      setListaProductosCombo([]);
      setisVisible_Combo(false);

      ToastWeb.show(`PRODUCTO AGREGADO:\n${productoComboSelect.Nombre}\n${TEXTO}`, { duration: 2000 });
  } else {
      setShowMaximoCombo(true);
  }
}

function QuitarProducto(Cod) {
  setProductosPedido(prev => {
      let nuevoCarrito = [...prev];
      const index = nuevoCarrito.findIndex(p => p.Gui === Cod.Gui);
      if (index >= 0) {
          if (nuevoCarrito[index].Cantidad === 1) {
              nuevoCarrito.splice(index, 1);
          } else {
              nuevoCarrito[index].Cantidad -= 1;
          }
      }
      return nuevoCarrito;
  });
}

function QuitarProductoCombo(Cod) {
  setProductosCombo(prev => prev.filter(p => p.Id !== Cod.Id));
}

function ContinuarProductoHijo(hijito) {
  let total =hijito.Precio;
  let guiiid = generateUUID();

  let nuevoProducto = {
      Gui: guiiid,
      Cantidad: 1,
      Producto: { ...ProductoHijoSelect, _PrecioActual: total },
      PrecioUnitario: total,
      EsExtra: false,
      ProductoHijo: hijito,
      ListaProductosCombo: ` - ${hijito.NombreProducto}`
  };
console.log("ingreso",nuevoProducto);
  setProductosPedido(prev => [...prev, nuevoProducto]);
  setisVisible_Hijos(false);
  ToastWeb.show(`PRODUCTO AGREGADO:\n${ProductoHijoSelect.Nombre } - ${hijito.NombreProducto} `, { duration: 2000 });
}




function Registrar()
{
  if(productosPedido.length>0)
  setShowRegistrar(true);
  else
    setShowSeleccioneProductos(true);  
}

function AgregarDetalle(Cod)
{
 setProductoComboSelect(Cod);
  setisVisible_Detalle(true);
}
const EmpresaButton = ({ almacen, LocalSelect, BotonEmpresaIn, BotonEmpresaOut, TextoEmpresaIn, TextoEmpresaOut, onPress }) => (
  <Pressable   
    onPress={onPress}
    style={almacen.Id === LocalSelect ? BotonEmpresaIn : BotonEmpresaOut}
  >
    <Text style={almacen.Id === LocalSelect ? TextoEmpresaIn : TextoEmpresaOut}>
      {almacen.Empresa.Razonsocial}
    </Text>
  </Pressable>
);

const ModalComponent = ({ 
  isVisible, 
  setIsVisible, 
  title, 
  data, 
  renderItem, 
  onContinue,
  SinBoton 
}) => (
  <Modal
    isVisible={isVisible}
    setIsVisible={setIsVisible}
  >
    <View>
      <Text style={Styles.modalTitle}>{title}</Text>
      {data.length > 0 && (
        <View>
          <FlatList
            numColumns={2}
            style={Styles.flatList}
            showsVerticalScrollIndicator={false}
            data={data}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
            onScroll={(e) => scrollY.setValue(e.nativeEvent.contentOffset.y)}
          />
          <View>
            {SinBoton!=true&&
            <Button
              onPress={onContinue}
              titleStyle={Styles.buttonTitle}
              title="CONTINUAR"
              buttonStyle={Styles.button}
            />}
          </View>
        </View>
      )}
    </View>
  </Modal>
);

const handlePress = (almacen) => {
  setBuscarProducto("");
  ListarCategoriasProductos(almacen.Id, almacen.Empresa.Locales[0].Id);
  setColapsableBuscando(true);
};
/*
  return (
    <>
    <CabeceraSecundariaScreem texto={"Agregar pedido - "+(props.route.params.emesa==null?"0":props.route.params.emesa.Nombre.replace("\n"," - ")+" - "+props.route.params.emesa.Grupo)}/>
    <SafeAreaView style={{ flex: 1,backgroundColor:"#fff" }}>
      {ListaAlmacenes.length>1&&
        <View style={{flexDirection:"row"}}>
          {ListaAlmacenes.map((almacen, index) => (
        <EmpresaButton 
          key={index}
          almacen={almacen}
          LocalSelect={LocalSelect}
          BotonEmpresaIn={Styles.BotonEmpresaIn}
          BotonEmpresaOut={Styles.BotonEmpresaOut}
          TextoEmpresaIn={Styles.TextoEmpresaIn}
          TextoEmpresaOut={Styles.TextoEmpresaOut}
          onPress={() => handlePress(almacen)}
        />
      ))}
        </View>
      }
      <SearchBar
      placeholder='buscar producto...'
      autoCorrect={false}
      autoCapitalize="characters"
      lightTheme={true}
      onKeyPress={(presiono)=>presiono.key=="Enter"&&Keyboard.dismiss()}
      onChange={(e)=>{setBuscarProducto(e.nativeEvent.text); setColapsableBuscando(e.nativeEvent.text.trim()=="")}}
      value={buscarProducto}
      onClear={()=>{setBuscarProducto("");setColapsableBuscando(true)}}
      labelStyle={{fontFamily:"FontLight"}}
      inputStyle={{backgroundColor:"#fff",color:"#444"}}
      inputContainerStyle={{backgroundColor:"#fff"}}
      containerStyle={{backgroundColor:"#fff"}}
      round
    />
    <View style={{flex:1}}>
      {size(listaProductosFiltrado) > 0 && (
        <View style={{flex:1,marginHorizontal:10}}>
          <FlatList
          ref={refContainer3}
          data={categorias}
          renderItem={(itemm) => (
            size(listaProductosFiltrado.filter(
              (p) => p.Grupo.Nombre == itemm.item.Nombre
            ))>0&&
            <FlatList
            ListHeaderComponent={
              <TouchableOpacity
              style={{
                height:35,
                borderColor: COLOR_EMPRESARIAL,
                borderWidth:2,
                borderRadius:10,
                marginTop:10,
                alignItems:"center",
                justifyContent:"center"
              }}
              onPress={()=>{
                console.log("i",itemm);
                itemm.item.Id==idIsColapsable?setIdColapsable(-1):setIdColapsable(itemm.item.Id);
               }}>
                <Text style={{color:COLOR_EMPRESARIAL,fontFamily: "FontBold",fontSize: 15,alignSelf:"center"}}>
                  {itemm.item.Nombre}
                </Text>
              </TouchableOpacity>
            }
            style={{ marginHorizontal:10 }}
            showsVerticalScrollIndicator={false}
            ItemSeparatorComponent={((!colapsableBuscando)||idIsColapsable==itemm.item.Id)&&<View style={{borderBottomColor:"#ddd",borderBottomWidth:1}}></View>}
            data={listaProductosFiltrado.filter(
              (p) => p.Grupo.Nombre == itemm.item.Nombre
            )}
            renderItem={(ddd) => (
              ((!colapsableBuscando)||idIsColapsable==itemm.item.Id)&&<ProductoItem cantidad={productosPedido.filter(p=>p.Producto.Id==ddd.item.Id).length>0?productosPedido.filter(p=>p.Producto.Id==ddd.item.Id).reduce((a,b)=>{return a+b.Cantidad},0):0} setClic={setClic} producto={ddd.item} setProductoClic={setProductoClic} />
            )}
            keyExtractor={(item, index) => index.toString()}/>
          )}
          snapToAlignment="center"
          scrollEventThrottle={16}
          keyExtractor={(item, index) => index.toString()}/>
        </View>
      )}
      <TouchableOpacity onPress={()=>setVisiblePedido(true)} style={{ flexDirection: "row", paddingHorizontal: 10, backgroundColor: "#fff", alignItems: "center", paddingVertical:5 }}>
        <Text style={{ flex:1,textAlignVertical:"center", textAlign:"right", fontFamily:"FontBold", fontSize:18, color:COLOR_EMPRESARIAL}}>
          {"("+productosPedido.reduce((a,b)=>a+b.Cantidad,0)+") item(s)    => " +"TOTAL    S/ "}{ productosPedido.length>0?productosPedido.reduce((a,b)=>a+b.Cantidad*b.Producto._PrecioActual,0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","):(0).toFixed(2)}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => setisVisible_Observacion(true)} style={{flexDirection: "row",justifyContent: "space-between",paddingVertical: 10}}>
        <View style={{ flex: 1,flexDirection:"row", marginHorizontal:10 }}>
          <Text style={{ fontFamily: "FontBold",fontSize:15 }}>{"Observación:\t"}</Text>
          <Text numberOfLines={3} style={{ marginBottom: 5, paddingBottom: 5, fontFamily: "FontRegular", fontSize:15 }}>{pedido.Observaciones}</Text>
        </View>
      </TouchableOpacity>
      <Button
      onPress={()=>{
        setLoadingTrans(true);
        RegistrarComanda(productosPedido,props.route.params.emesa,pedido.Observaciones)
        .then((XXXX)=> setShowExito(true))
        .catch((er)=>console.log("ER",er))
        .finally(()=>setLoadingTrans(false));
        setShowRegistrar(false);
      }}//}Registrar()
      titleStyle={{fontFamily:"FontBold", fontSize:18}} title={"GENERAR COMANDA"} buttonStyle={{backgroundColor:COLOR_EMPRESARIAL, marginHorizontal:10,marginVertical:5}}/>
    </View>
    <ModalAbajo
    isVisible={isVisible_Observacion} setIsVisible={setisVisible_Observacion}
    children={
      <ViewObservacion Seleccionado={pedido} setSeleccionado={setPedido} tObservacion={Observaciones}
       setTObservacion={setTObservacion} setIsVisible={setisVisible_Observacion}/>
    }/>
    <Modal
    isVisible={isVisiblePedido} setIsVisible={setVisiblePedido}
    children={
      <View>
        <FlatList
        showsVerticalScrollIndicator={true}
        data={productosPedido}
        style={{flex:1}}
        renderItem={(cate) => ( 
          <LineaCuota
          item={cate.item}
          AgregarDetalle={AgregarDetalle}
          setClic={setClic}
          setProductoClic={setProductoClic}/>
        )}
        keyExtractor={(item, index) => index.toString()}/>
      </View>
    }/>
               <Modal
                isVisible={isVisible_Combo}
                setIsVisible={setisVisible_Combo}
                children={
                  <View><Text style={{alignItems: "flex-start",
                  fontFamily: "FontBold",
                  color: "#444",
                  fontSize:18}}>Seleccionar {productoComboSelect.MaxCombo} Productos Combo - {productoComboSelect.Nombre}</Text>
                    {size(listaProductosCombo) > 0 && (
                      <View>
                            <FlatList
                            numColumns={2}
                            style={{height:height/2}}
                              showsVerticalScrollIndicator={false}
                              data={listaProductosCombo}
                              renderItem={(ddd) => (
                                <ProductoItemCombo cantidad={productosCombo.findIndex((p) => p.Id == ddd.item.Id)} setClic={setClic1} producto={ddd.item} setProductoClic={setProductoClic1} />
                              )}
                              keyExtractor={(item, index) => index.toString()}
                              onScroll={(e) =>{ 
                                scrollY.setValue(e.nativeEvent.contentOffset.y)
                              }}
                            />
                            <View>
                            <Button
                            onPress={()=>ContinuarProductoCombo()}
                            titleStyle={{fontFamily:"FontBold", fontSize:18}}
                            title={"CONTINUAR"}
                            buttonStyle={{backgroundColor:COLOR_EMPRESARIAL, marginTop:10}}></Button>
                              </View>
                      </View>
                    ) }
                    </View>
                }
              />
              <Modal
                isVisible={isVisible_Extra}
                setIsVisible={setIsVisible_Extra}
                children={
                  <View><Text style={{alignItems: "flex-start",
                  fontFamily: "FontBold",
                  color: "#444",
                  fontSize:18}}>{"Seleccionar Adicionales\n->  "+productosExtraSelect.Nombre}</Text>
                    {size(listaProductosExtra) > 0 && (
                      <View>
                            <FlatList
                            numColumns={2}
                            style={{height:height/2}}
                              showsVerticalScrollIndicator={false}
                              data={listaProductosExtra}
                              renderItem={(ddd) => (
                                <ProductoItemExtra cantidad={productosExtra.findIndex((p) => p.Id == ddd.item.Id)} setClic={setClic2} producto={ddd.item} setProductoClic={setProductoClic2} />
                              )}
                              keyExtractor={(item, index) => index.toString()}
                              onScroll={(e) =>{ 
                                scrollY.setValue(e.nativeEvent.contentOffset.y)
                              }}
                              />
                            <View>
                            <Button
                            onPress={()=>ContinuarProductoExtra()}
                            titleStyle={{fontFamily:"FontBold", fontSize:18}}
                            title={"CONTINUAR"}
                            buttonStyle={{backgroundColor:COLOR_EMPRESARIAL, marginTop:10}}></Button>
                              </View>
                      </View>
                    ) }
                    </View>
                }
              />
              <Modal
                isVisible={isVisible_Estado}
                setIsVisible={setIsVisible_Estado}
                children={
                  <View><Text style={{alignItems: "flex-start",
                  fontFamily: "FontBold",
                  color: "#444",
                  fontSize:18}}>{"Seleccionar Estado\n->  "+productosEstadoSelect.Nombre}</Text>
                    {size(listaProductosEstado) > 0 && (
                      <View>
                            <FlatList
                            numColumns={2}
                            style={{height:height/2}}
                              showsVerticalScrollIndicator={false}
                              data={listaProductosEstado}
                              renderItem={(ddd) => (
                                <ProductoItemEstado cantidad={estado?estado.Id==ddd.item.Id?1:0:0} setClic={setClic3} producto={ddd.item} setProductoClic={setProductoClic3} />
                              )}
                              keyExtractor={(item, index) => index.toString()}
                              onScroll={(e) =>{ 
                                scrollY.setValue(e.nativeEvent.contentOffset.y)
                              }}
                              />
                            <View>
                            <Button
                            onPress={()=>ContinuarProductoEstado()}
                            titleStyle={{fontFamily:"FontBold", fontSize:18}}
                            title={"CONTINUAR"}
                            buttonStyle={{backgroundColor:COLOR_EMPRESARIAL, marginTop:10}}></Button>
                              </View>
                      </View>
                    ) }
                    </View>
                }
              />

<ModalAbajo
                isVisible={isVisible_Detalle}
                setIsVisible={setisVisible_Detalle}
                children={
                    <ViewDetalle
                    Seleccionado={productoComboSelect}
                    setSeleccionado={setProductoComboSelect}
                    tObservacion={txtDetalle}
                    productosPedido={productosPedido}
                    setProductosPedido={setProductosPedido}
                    setTObservacion={setTxtDetalle}
                    setIsVisible={setisVisible_Detalle}
                  />
               }
              />
          <Toast
                          position="bottom"
                          positionValue={50}
                          opacity={0.8}
                          ref={toastRef}
                        />
      <LoadingTransparente isVisible={loadingTrans} />
  <AwesomeAlert
          show={showRegistrar}
          showProgress={false}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          cancelButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          title="Alerta"
          message={"¿Esta seguro de registrar el pedido en la mesa "+props.route.params.emesa.Nombre+" - "+props.route.params.emesa.Grupo+"?"}
          closeOnTouchOutside={true}
          onDismiss={()=>{            setShowRegistrar(false);
          }}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          showCancelButton={true}
          confirmText="Si"
          cancelTextText="No"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowRegistrar(false);
          }}
          onConfirmPressed={() => {
            setLoadingTrans(true);
          RegistrarComanda(productosPedido,props.route.params.emesa,pedido.Observaciones)
          .then((XXXX)=>{ setLoadingTrans(false);
            setShowExito(true);
            })
          .catch((er)=>{ console.log("ER",er); setLoadingTrans(false)});
          setShowRegistrar(false);
          }}
        />
  <AwesomeAlert
          show={showLimpiar}
          showProgress={false}
          title="Alerta"
          message={"¿Esta seguro de limpiar la mesa "+(props.route.params.emesa==null?"0":props.route.params.emesa.Nombre.replace("\n"," - ")+" - "+props.route.params.emesa.Grupo)+"?"}
          closeOnTouchOutside={true}
          onDismiss={()=>{            setShowLimpiar(false);
          }}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          showCancelButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          cancelButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Si"
          cancelText="No"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowLimpiar(false);
          }}
          onConfirmPressed={() => {
            setLoadingTrans(true);
          setProductosPedido([]),
          setPedido({Observaciones:""});
          setLoadingTrans(false);
          setShowLimpiar(false);
          }}
        />
      <AwesomeAlert
          show={showMaximoCombo}
          showProgress={false}
          title="Error"
          message={"Solo puede seleccionar "+productoComboSelect.MaxCombo+ " productos"}
          closeOnTouchOutside={true}
          onDismiss={()=>{            setShowMaximoCombo(false);
          }}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowMaximoCombo(false);
          }}
          onConfirmPressed={() => {
            setShowMaximoCombo(false);
          }}
        />
        <AwesomeAlert
          show={showExito}
          showProgress={false}
          title="Exito"
          message="Pedido agregado correctamente!"
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
          props.route.params.setRegreso(Date.now);
            navigation.goBack()
            setShowExito(false);
          }}
          onConfirmPressed={() => {
          props.route.params.setRegreso(Date.now);
            navigation.goBack()
            setShowExito(false);
          }}
        />
        <AwesomeAlert
          show={showSeleccioneProductos}
          showProgress={false}
          title="Error"
          message="Seleccione un producto para continuar con el registro."
          closeOnTouchOutside={true}
          onDismiss={()=>{setShowSeleccioneProductos(false)}}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowSeleccioneProductos(false);
          }}
          onConfirmPressed={() => {
            setShowSeleccioneProductos(false);
          }}
        />
    </SafeAreaView></>
  );*/

  return (
    <>
  <CabeceraSecundariaScreem 
    texto={`Agregar pedido - ${props.route.params.emesa == null ? "0" : props.route.params.emesa.Nombre.replace("\n", " - ") + " - " + props.route.params.emesa.Grupo}`} 
  />

  <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
    
    {/* Muestra la lista de almacenes si hay más de uno */}
    {ListaAlmacenes.length > 1 && (
      <View style={{ flexDirection: "row" }}>
        {ListaAlmacenes.map((almacen, index) => (
          <EmpresaButton 
            key={index}
            almacen={almacen}
            LocalSelect={LocalSelect}
            BotonEmpresaIn={Styles.BotonEmpresaIn}
            BotonEmpresaOut={Styles.BotonEmpresaOut}
            TextoEmpresaIn={Styles.TextoEmpresaIn}
            TextoEmpresaOut={Styles.TextoEmpresaOut}
            onPress={() => handlePress(almacen)}
          />
        ))}
      </View>
    )}

    {/* Barra de búsqueda */}
    <SearchBar
      placeholder="Buscar producto..."
      autoCorrect={false}
      autoCapitalize="characters"
      lightTheme={true}
      onKeyPress={(event) => event.key === "Enter" && Keyboard.dismiss()}
      onChange={(e) => {
        setBuscarProducto(e.nativeEvent.text);
        setColapsableBuscando(e.nativeEvent.text.trim() === "");
      }}
      value={buscarProducto}
      onClear={() => {
        setBuscarProducto("");
        setColapsableBuscando(true);
      }}
      labelStyle={{ fontFamily: "FontLight" }}
      inputStyle={{ backgroundColor: "#fff", color: "#444" }}
      inputContainerStyle={{ backgroundColor: "#fff" }}
      containerStyle={{ backgroundColor: "#fff" }}
      round
    />

    {/* Lista de productos filtrados por categorías */}
    <View style={{ flex: 1 }}>
      {size(listaProductosFiltrado) > 0 && (
        <View style={{ flex: 1, marginHorizontal: 10 }}>
          <FlatList
            ref={refContainer3}
            data={categorias}
            renderItem={({ item }) => {
              const productosEnGrupo = listaProductosFiltrado.filter(p => p.Grupo.Nombre === item.Nombre);
              
              if (size(productosEnGrupo) > 0) {
                return (
                  <FlatList
                    ListHeaderComponent={
                      <Pressable
                        style={{
                          height: 35,
                          borderColor: COLOR_EMPRESARIAL,
                          borderWidth: 2,
                          borderRadius: 10,
                          marginTop: 10,
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                        onPress={() => {
                          item.Id === idIsColapsable ? setIdColapsable(-1) : setIdColapsable(item.Id);
                        }}
                      >
                        <Text style={{ color: COLOR_EMPRESARIAL, fontFamily: "FontBold", fontSize: 15, alignSelf: "center" }}>
                          {item.Nombre}
                        </Text>
                      </Pressable>
                    }
                    style={{ marginHorizontal: 10 }}
                    showsVerticalScrollIndicator={false}
                    data={productosEnGrupo}
                    renderItem={(itemLinea) => {
                      const cantidad = productosPedido
                        .filter(p => p.Producto.Id === itemLinea.Id)
                        .reduce((a, b) => a + b.Cantidad, 0);
                      return (
                        ((!colapsableBuscando) || idIsColapsable === item.Id) && (
                          <ProductoItem
                            cantidad={cantidad}
                            setClic={setClic}
                            producto={itemLinea.item}
                            setProductoClic={setProductoClic}
                          />
                        )
                      );
                    }}
                    keyExtractor={(item, index) => index.toString()}
                  />
                );
              }
            }}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      )}
    </View>

    {/* Muestra el total del pedido */}
    <Pressable
      onPress={() => setVisiblePedido(true)}
      style={{ flexDirection: "row", paddingHorizontal: 10, backgroundColor: "#fff", alignItems: "center", paddingVertical: 5 }}
    >
      <Text style={{ flex: 1, textAlign: "right", fontFamily: "FontBold", fontSize: 18, color: COLOR_EMPRESARIAL }}>
        {`(${productosPedido.reduce((a, b) => a + b.Cantidad, 0)}) item(s)    => TOTAL    S/ `}
        {productosPedido.length > 0
          ? productosPedido.reduce((a, b) => a + b.Cantidad * b.Producto._PrecioActual, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : (0).toFixed(2)}
      </Text>
    </Pressable>

    {/* Botón para agregar observaciones */}
    <Pressable
      onPress={() => setisVisible_Observacion(true)}
      style={{ flexDirection: "row", justifyContent: "space-between", paddingVertical: 10 }}
    >
      <View style={{ flex: 1, flexDirection: "row", marginHorizontal: 10 }}>
        <Text style={{ fontFamily: "FontBold", fontSize: 15 }}>{"Observación:\t"}</Text>
        <Text numberOfLines={3} style={{ marginBottom: 5, paddingBottom: 5, fontFamily: "FontRegular", fontSize: 15 }}>
          {pedido.Observaciones}
        </Text>
      </View>
    </Pressable>

    {/* Botón para generar la comanda */}
    <Button
      onPress={() => {
        setLoadingTrans(true);
        RegistrarComanda(productosPedido, props.route.params.emesa, pedido.Observaciones)
          .then(() => setShowExito(true))
          .catch(error => console.log("Error al registrar:", error))
          .finally(() => setLoadingTrans(false));
        setShowRegistrar(false);
      }}
      titleStyle={{ fontFamily: "FontBold", fontSize: 18 }}
      title="GENERAR COMANDA"
      buttonStyle={{ backgroundColor: COLOR_EMPRESARIAL, marginHorizontal: 10, marginVertical: 5 }}
    />

    {/* Modales para diferentes acciones y alertas */}
    <ModalAbajo isVisible={isVisible_Observacion} setIsVisible={setisVisible_Observacion}>
      <ViewObservacion Seleccionado={pedido} setSeleccionado={setPedido} tObservacion={Observaciones} setTObservacion={setTObservacion} setIsVisible={setisVisible_Observacion}/>
    </ModalAbajo>

    <Modal isVisible={isVisiblePedido} setIsVisible={setVisiblePedido}>
      <View style={{ flex: 1 }}>
        <FlatList data={productosPedido} renderItem={({ item }) => (
          <LineaCuota item={item} AgregarDetalle={AgregarDetalle} setClic={setClic} setProductoClic={setProductoClic}/>
        )}
        keyExtractor={(item, index) => index.toString()}
        showsVerticalScrollIndicator={true}
        contentContainerStyle={{ paddingVertical: 10 }}
        />
      </View>
    </Modal>
    <ModalComponent isVisible={isVisible_Combo} setIsVisible={setisVisible_Combo} title={`Seleccionar ${productoComboSelect.MaxCombo} Productos Combo - ${productoComboSelect.Nombre}`}
    data={listaProductosCombo}
    renderItem={({ item }) => (
      <ProductoItemCombo cantidad={productosCombo.findIndex((p) => p.Id === item.Id)} setClic={setClic1} producto={item} setProductoClic={setProductoClic1}/>
    )}
    onContinue={ContinuarProductoCombo}/>
    
    <ModalComponent isVisible={isVisible_Extra} setIsVisible={setIsVisible_Extra} title={`Seleccionar Adicionales\n->  ${productosExtraSelect.Nombre}`}
    data={listaProductosExtra}
    renderItem={({ item }) => (
      <ProductoItemExtra cantidad={productosExtra.findIndex((p) => p.Id === item.Id)} setClic={setClic2} producto={item} setProductoClic={setProductoClic2}/>
    )}
    onContinue={ContinuarProductoExtra}/>
    
    <ModalComponent isVisible={isVisible_Estado} setIsVisible={setIsVisible_Estado} title={`Seleccionar Estado\n->  ${productosEstadoSelect.Nombre}`}
    data={listaProductosEstado}
    renderItem={({ item }) => (
      <ProductoItemEstado cantidad={estado ? (estado.Id === item.Id ? 1 : 0) : 0} setClic={setClic3} producto={item} setProductoClic={setProductoClic3}/>
    )}
    onContinue={ContinuarProductoEstado}/>

<ModalComponent SinBoton={true} isVisible={isVisible_Hijos} setIsVisible={setisVisible_Hijos} title={`Seleccionar Presentación\n->  ${ProductoHijoSelect.Nombre}`}
    data={ProductoHijoSelect.Presentaciones}
    renderItem={({ item }) => (
      <ProductoItemHijo  producto={item} OnPress={()=>ContinuarProductoHijo(item)}/>
    )}
    onContinue={ContinuarProductoEstado}/>
    
    <ModalAbajo isVisible={isVisible_Detalle} setIsVisible={setisVisible_Detalle}>
      <ViewDetalle Seleccionado={productoComboSelect} setSeleccionado={setProductoComboSelect} tObservacion={txtDetalle} productosPedido={productosPedido}
      setProductosPedido={setProductosPedido} setTObservacion={setTxtDetalle} setIsVisible={setisVisible_Detalle}/>
    </ModalAbajo>

    <Toast position="bottom" positionValue={50} opacity={0.8} ref={toastRef} />
    <LoadingTransparente isVisible={loadingTrans} />
    
    {/* Alertas de confirmación */}
    <AwesomeAlert
      show={showRegistrar}
      showProgress={false}
      title="Alerta"
      message={`¿Está seguro de registrar el pedido en la mesa ${props.route.params.emesa.Nombre} - ${props.route.params.emesa.Grupo}?`}
      closeOnTouchOutside={true}
      showConfirmButton={true}
      showCancelButton={true}
      confirmText="Si"
      cancelText="No"
      onCancelPressed={() => setShowRegistrar(false)}
      onConfirmPressed={() => {
        setLoadingTrans(true);
        RegistrarComanda(productosPedido, props.route.params.emesa, pedido.Observaciones)
          .then(() => {
            setLoadingTrans(false);
            setShowExito(true);
          })
          .catch(error => {
            console.log("Error:", error);
            setLoadingTrans(false);
          });
        setShowRegistrar(false);
      }}
    />
    
    <AwesomeAlert
          show={showLimpiar}
          showProgress={false}
          title="Alerta"
          message={"¿Esta seguro de limpiar la mesa "+(props.route.params.emesa==null?"0":props.route.params.emesa.Nombre.replace("\n"," - ")+" - "+props.route.params.emesa.Grupo)+"?"}
          closeOnTouchOutside={true}
          onDismiss={()=>{            setShowLimpiar(false);
          }}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          showCancelButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          cancelButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Si"
          cancelText="No"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowLimpiar(false);
          }}
          onConfirmPressed={() => {
            setLoadingTrans(true);
          setProductosPedido([]),
          setPedido({Observaciones:""});
          setLoadingTrans(false);
          setShowLimpiar(false);
          }}
        />
      <AwesomeAlert
          show={showMaximoCombo}
          showProgress={false}
          title="Error"
          message={"Solo puede seleccionar "+productoComboSelect.MaxCombo+ " productos"}
          closeOnTouchOutside={true}
          onDismiss={()=>{            setShowMaximoCombo(false);
          }}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowMaximoCombo(false);
          }}
          onConfirmPressed={() => {
            setShowMaximoCombo(false);
          }}
        />
        <AwesomeAlert
          show={showExito}
          showProgress={false}
          title="Exito"
          message="Pedido agregado correctamente!"
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
          props.route.params.setRegreso(Date.now);
            navigation.goBack()
            setShowExito(false);
          }}
          onConfirmPressed={() => {
          props.route.params.setRegreso(Date.now);
            navigation.goBack()
            setShowExito(false);
          }}
        />
        <AwesomeAlert
          show={showSeleccioneProductos}
          showProgress={false}
          title="Error"
          message="Seleccione un producto para continuar con el registro."
          closeOnTouchOutside={true}
          onDismiss={()=>{setShowSeleccioneProductos(false)}}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          messageStyle={{fontSize:20, fontFamily:"FontRegular"}}
          titleStyle={{fontSize:20, fontFamily:"FontRegular"}}
          confirmButtonTextStyle={{fontSize:30, fontFamily:"FontBold"}}
          confirmText="Ok"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowSeleccioneProductos(false);
          }}
          onConfirmPressed={() => {
            setShowSeleccioneProductos(false);
          }}
        />
    {/* Similar awesome alerts for other scenarios */}
  </SafeAreaView>
</>
  )
}



class ProductoItem extends React.PureComponent {
  handlePress = () => {
    const { producto, setClic, setProductoClic } = this.props;
    if (producto.Activo) {
      setClic(Date.now());
      setProductoClic({ agregar: true, producto });
    }
  };

  render() {
    const { producto, cantidad } = this.props;
    return (
      <Pressable
        onPress={this.handlePress}
        style={Styles.container}
      >
        <View style={Styles.innerContainer}>
          <View style={Styles.row}>
            {cantidad > 0 && (
              <View style={Styles.badgeContainer}>
                <Badge
                  value={cantidad}
                  status="error"
                  containerStyle={Styles.badge}
                />
              </View>
            )}
            <View style={Styles.textContainer}>
              <Text style={Styles.productName}>
                {producto.Nombre}
              </Text>
              <View style={Styles.balanceContainer}>
                <Text style={[Styles.balanceText, { color: producto.Activo ? "#444" : "red" }]}>
                  Saldo: {producto.Activo ? producto.StockActual : "NO DISPONIBLE"}
                </Text>
                <Text style={Styles.priceText}>
                  S/ {parseFloat(producto._PrecioActual).toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Pressable>
    );
  }
}